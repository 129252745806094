@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  /* box-sizing: border-box; */
}

@font-face {
	font-family: MonumentExtendedLight;
	src: url("./fonts/PPMonumentExtended-Light.otf");
}

@font-face {
	font-family: MonumentExtended;
	src: url("./fonts/MonumentExtended-Light.ttf");
}

@font-face {
	font-family: MonumentExtendedBold;
	src: url("./fonts/MonumentExtended-Regular.otf");
}

@font-face {
	font-family: FraktionSans;
	src: url("./fonts/PPFraktionSans-Variable.ttf");
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  background-color: black;
}

#root {
  overflow: auto;
}

body {
  position: fixed;
  overflow: hidden;
  overscroll-behavior-y: none;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial, sans-serif;
  color: black;
  background: black;
}

p {
  @apply font-primary
}

.glass {
  @apply p-8 backdrop-blur-xl backdrop-brightness-50 border border-white/10 rounded-sm;
}

.border-card {
  @apply p-8 border border-white/10 rounded-sm;
}

.button {
  @apply w-full border-white/90 border text-white bg-transparent hover:bg-white hover:text-black p-4 block text-center rounded-sm transition-all font-mono duration-700;
}

.cta-button {
  @apply border-white/80 border backdrop-blur-md backdrop-brightness-75 bg-black/20 rounded-sm p-3 text-center font-medium hover:bg-white transition-all hover:text-black cursor-pointer font-mono;
}

.section-thing {
  @apply px-4 py-8 md:px-40 mx-auto text-white items-center md:justify-between md:h-screen flex md:flex-row flex-col;
}

.section-thing img {
  @apply h-60 max-w-[min(500px,80vw)] object-contain mx-auto;
}

.blur-and-dark {
  @apply backdrop-blur-2xl border-y border-white/20 backdrop-brightness-50;
}

.faq h3 {
  @apply font-bold text-2xl mb-3;
}
